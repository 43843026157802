/*///////////////////////////////////////////////////
// ファイル名 :SuccsessForm.css
// 説明 : SuccsessForm.tsxのスタイルシート
// 補足 : 
// 作成日: 2023/07/07
// 制作者: 大濵 聖 (WITSTUDIO)
////////////////////////////////////////////////////*/

.succsesspage_body {
    text-align: center;
    margin: 0 auto;
    max-width: 500px;
    width: 90%;
    min-height: 100vh;
    position: relative;/*←相対位置*/
}

.succsesspage_body img {
    width: 120px;
}

.succsesspage_body p {
    font-size: 15px;
}

footer {
    font-size: 15px;
    color: #999;
    background: #fff;
    text-align: left;
    position: absolute;/*←絶対位置*/
    bottom: 0; /*下に固定*/
    max-width: 500px;
    width: 100%;
}

footer a {
    color: #999;
    text-decoration: none;
    text-align: center;
}

footer a:hover {
    color: #000;
}

hr {
    height: 1px;
    border: 0;
    border-top: 1px solid #e5e7eb;
}

.footer__logo {
    display: inline-block;
}