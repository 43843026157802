/*///////////////////////////////////////////////////
// ファイル名 :MainApp.css
// 説明 : MainApp.tsxのスタイルシート
// 補足 : 
// 作成日: 2023/07/07
// 制作者: 大濵 聖 (WITSTUDIO)
////////////////////////////////////////////////////*/

.mainapp_body {
    width: 90%;
    text-align: center;
    margin: 0 auto;
    max-width: 500px;
    min-height: 100vh;
    position: relative;/*←相対位置*/
    padding-bottom: 120px;
}

.mainapp_body img {
    width: 120px;
}

.mainapp_body h1 {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mainapp_body h2 {
    font-size: 20px;
    text-align: left;
    margin-top: 5px;
}

.mainapp_body p {
    font-size: 15px;
    text-align: left;
}

.whiteBlank50 {
    margin-top: 10px;
}

footer {
    font-size: 15px;
    color: #999;
    background: #fff;
    text-align: left;
    position: absolute;/*←絶対位置*/
    bottom: 0; /*下に固定*/
    max-width: 500px;
    width: 100%;
}

footer a {
    color: #999;
    text-decoration: none;
    text-align: center;
}

footer a:hover {
    color: #000;
}

hr {
    height: 1px;
    border: 0;
    border-top: 1px solid #e5e7eb;
}

.footer__logo {
    display: inline-block;
}
