/*///////////////////////////////////////////////////
// ファイル名 :NotFound.css
// 説明 : NotFound.tsxのスタイルシート
// 補足 : 
// 作成日: 2023/07/07
// 制作者: 大濵 聖 (WITSTUDIO)
////////////////////////////////////////////////////*/

.notfound_body {
    text-align: center;
    margin: 0 auto;
    max-width: 500px;
}

.notfound_body img {
    width: 120px;
}

.notfound_body p {
    font-size: 15px;
}